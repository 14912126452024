* {
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
  width: 30%;
  margin: 30vh auto;
}

h2 {
  margin-top: 20px;
}
