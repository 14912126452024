:root {
  --x: 0;
  --y: 0;
}

.treehouse-frog {
  width: 100%;
  height: 0;
  padding-top: 60%;
  border-radius: 50%;
  background: #92ae57;
  position: relative;
}
.treehouse-frog [class*="eye"] {
  background: #ffe13b;
  border: 10px solid #92ae57;
  width: 45.45%;
  height: 68.18%;
  position: absolute;
  top: -18.18%;
  left: -4.54%;
  border-radius: 50%;
}
.treehouse-frog .right-eye {
  left: auto;
  right: -4.54%;
}
.treehouse-frog [class*="eye"]:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 35%;
  height: 38%;
  border-radius: 50%;
  margin-left: -17.2%;
  margin-top: -19%;
  background: black;
  transition: transform 0.1s;
  transform: translate(
    calc(-50% + 90px * (var(--x))),
    calc(-50% + 90px * (var(--y)))
  );
}
.treehouse-frog:before,
.treehouse-frog:after {
  content: "";
  position: absolute;
  top: 56.81%;
  background: #6f8346;
  border-radius: 50%;
  left: 43.93%;
  width: 6%;
  height: 10%;
  z-index: 1;
}
.treehouse-frog:after {
  left: 52.12%;
}
.treehouse-frog .mouth {
  position: absolute;
  top: 60%;
  left: 1.7%;
  width: 100%;
  height: 50%;
  overflow: hidden;
}
.treehouse-frog .mouth:after,
.treehouse-frog .mouth:before {
  content: "";
  position: absolute;
  top: -50%;
  left: 0%;
  width: 97%;
  height: 100%;
  border-radius: 50%;
  background: #71803f;
}
.treehouse-frog .mouth:after {
  background: #92ae57;
  height: 80%;
  top: -40%;
  left: 0%;
}
